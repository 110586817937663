import { Injectable } from '@angular/core';
import { createClient, Entry, EntryCollection } from 'contentful';


const CONFIG = {
  space: '4j06ohwvfhqn',
  accessToken: 'l9gLfVjjF-WaX5kyslnxF6ER-A8XNjB6yeNlpo-OCZM'
}

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  private client = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  posts: Promise<Entry<any>[]>;


  constructor() { }

  getSpecificPost(id: string): Promise<Entry<any>[]>{
    return this.client.getEntry(id).then();
  }

  getPosts(): Promise<Entry<any>[]>{
    return this.posts;
  }

  setPosts(posts: Entry<any>[]){
    // this.posts = posts;

  }

  savePosts(){
    this.posts = this.client.getEntries().then(res => res.items);
  }

  getPostsFromCont(query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries().then(res => res.items);
  }
}
