<p>projects works!</p>
<ul>
    <li>Angular2</li>
    <li>Typescript</li>
    <li>Java</li>
    <li>Python</li>
    <li>CI/CD</li>
    <li>Docker</li>
    <li>Kubernetees</li>
    <li>UI/UX</li>  
</ul>