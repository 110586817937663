import { Component, ElementRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Entry } from 'contentful';
import { ContentfulService } from '../contentful.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { RetroTileComponent } from './retro-tile/retro-tile.component';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    providers: [ContentfulService],
    standalone: false
})
export class BlogComponent implements OnInit {

  posts: Entry<any>[]=[];

  @ViewChild('abt') about: RetroTileComponent
  @ViewChild("skl") skills: ElementRef
  @ViewChild("exp") experience: ElementRef
  @ViewChild("edu") education: ElementRef

  private messageSubject = new Subject<string>();
  public message$ = this.messageSubject.asObservable();

  cur_message: string = '';
  
  constructor(private contentfulService: ContentfulService, private scroller: ViewportScroller) { }

  ngOnInit(): void {
    this.contentfulService.getPostsFromCont().then((res)=>{this.posts=res;});
    // this.contentfulService.setPosts(this.posts);
    this.contentfulService.savePosts();

    this.message$.subscribe((typedMessage:string)=>{
      this.cur_message = typedMessage
    })

    const messages = [
      "Full Stack Developer",
      "Aspiring Indie Game Developer",
      "Private Pilot in Training",
      "Rocky Mountain Regular"
    ];
    this.typeMessage(messages, 150, 2000);
    
    // this.about.nativeElement.ad
  }

  ngAfterViewInit(): void{

  }


  scroll(elementTag: ElementRef){
    // this.scroller.scrollToAnchor(elementId+'-spacer')
    // elementTag.scrollIntoView(true)
    // console.log(about.nat)
  }

  typeMessage(messages: string[], speed: number = 100, delay: number = 2000): void {
    let currentMessageIndex = 0;

    const typeNext = (message: string) => {
      let i = 0;
      const typeInterval = setInterval(() => {
        if (i < message.length) {
          this.messageSubject.next(message.substring(0, i + 1));
          i++;
        } else {
          clearInterval(typeInterval);
          setTimeout(() => backspaceMessage(message), delay);
        }
      }, speed);
    };

    const backspaceMessage = (message: string) => {
      let i = message.length;
      const backspaceInterval = setInterval(() => {
        if (i > 0) {
          this.messageSubject.next(message.substring(0, i - 1));
          i--;
        } else {
          clearInterval(backspaceInterval);
          currentMessageIndex = (currentMessageIndex + 1) % messages.length;
          setTimeout(() => typeNext(messages[currentMessageIndex]), 500);
        }
      }, speed*0.5);
    };

    typeNext(messages[currentMessageIndex]);
  }
  
}
