import process from 'process';
export function isNode() {
  /**
   * Polyfills of 'process' might set process.browser === true
   *
   * See:
   * https://github.com/webpack/node-libs-browser/blob/master/mock/process.js#L8
   * https://github.com/defunctzombie/node-process/blob/master/browser.js#L156
   **/
  return typeof process !== 'undefined' && !process.browser;
}
export function isReactNative() {
  return typeof window !== 'undefined' && 'navigator' in window && 'product' in window.navigator && window.navigator.product === 'ReactNative';
}
export function getNodeVersion() {
  return process.versions && process.versions.node ? `v${process.versions.node}` : process.version;
}
export function getWindow() {
  return window;
}
export function noop() {
  return undefined;
}