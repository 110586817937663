<!-- <div class="nav-container"> -->
    <!-- <div class="menu"> -->
        <!-- <span class="finger">☞</span>  -->
        <!-- <h1 class="name">TYLOR HERNDON</h1> -->
        <!-- <img src="../../../assets/pano.JPG" class="headshot">  -->
        
    <!-- </div> -->
    <!-- <div class="retro-circle"></div> -->
<!-- </div> -->
<div class="container">
    <div class="icons">
        <a>
            <div class="about-me"><img src="../../../assets/person_logo.png" width="60" height="60"><div class="icon-text">About Me</div></div>
    
        </a>
        
        <a [routerLink]="'/resume'">
            <div class="resume" ><img src="../../../assets/document_icon.png" width="45" height="45"><div class="icon-text">Resume</div></div>
    
        </a>
        
        <a [routerLink]="'/projects'">
            <div class="portfolio" ><div class="portfolio-icon">>_</div><div class="icon-text">Portfolio</div></div>
        </a>
    
    
        <a>
        <div class="linkedin" ><img src="../../../assets/linkedin.png" width="60" height="60"><div class="icon-text">LinkedIn</div></div>
    
        </a>
    </div>

</div>