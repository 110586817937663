import { trigger, transition, style, animate, query, sequence } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-falling-text',
    imports: [CommonModule],
    templateUrl: './falling-text.component.html',
    styleUrl: './falling-text.component.scss',
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(':enter', style({ transform: 'translateY(-100%)' }), { optional: true }),
                sequence([
                    query(':leave', animate('0.5s ease-in', style({ transform: 'translateY(100%)' })), { optional: true }),
                    query(':enter', animate('0.5s ease-out', style({ transform: 'translateY(0)' })), { optional: true })
                ])
            ]),
        ]),
    ]
})
export class FallingTextComponent {
  items = [
    'Full Stack Development',
    'Aspiring Indie Game Developer', 
    'Private Pilot in Training', 
    'Hobbiest Woodworker',
    "Musician (For My Dog)",
    "Rocky Mountain Regular",
    'Perfect Picleball Pardner',
    'Snowboarding Fiend',
    'Prolematic Plant Parent'

  ]; // Your list items
  currentItem: string;

  constructor() {
    this.currentItem = this.items[0];
    this.cycleItems();
  }

  cycleItems() {
    let index = 0;
    setInterval(() => {
      index = (index + 1) % this.items.length;
      this.currentItem = this.items[index];
    }, 2000); // Change item every 2000ms (2 seconds)
  }
}
