<div class="landing-container">
    <div class="elevator">

        <!-- <div class="square"> -->
        <!-- <p class="pitch"><b>Welcome</b> to my personal website! As a software developer with expertise in <b>Angular2,
                Typescript, Java, and Python.</b> I have experience building websites in the banking industry, as well as
            personal ML/AI projects. I am passionate about <b>learning new skills</b> and <b>solving complex problems</b>,
            and I am constantly seeking out new challenges to help me grow as a developer. Whether it's building
            <b>full-stack solutions</b> from scratch or working on a team to tackle a specific challenge, I am always up for
            the task. As you browse my website, I hope you'll get a sense of my skills, experience, and <b>passion for
                software development</b>. Whether you're an employer, recruiter, or simply a friend, I look forward to
            connecting with you and exploring new opportunities together.
        </p> -->
        <!-- <p class="pitch"> -->
    
            <div class="intro-container">
                <img class="desktop-pic" src="assets/mountain_selfie.jpg" alt="">
                <div class="typed-text-container">
    
                    <h1 class="name">TYLOR HERNDON</h1>
                    <img class="mobile-pic" src="assets/mountain_selfie.jpg" alt="">
    
                    <h2 class="role"><b>{{cur_message}}</b><span class="cursor">|</span></h2>
                    <br>
                    <p class="based">Based in Dallas</p> 
                </div>
                
            </div>
    
            
        <!-- </p> -->
        <!-- <ul>
            <li>Full Stack Developer</li>
            <li>Aspiring Indie Game Developer</li>
            <li>Private Pilot in Training</li>
            <li>Hobbiest Woodworker</li>
            <li>Musician (For My Dog)</li>
            <li>Rocky Mountain Regular</li>
            <li>Perfect Picleball Pardner</li>
            <li>Snowboarding Fiend</li>
            <li>Prolematic Plant Parent</li>
             
        </ul> -->
        
        <!-- <blog-typewritter></blog-typewritter> -->
        <!-- </div> -->
    
    </div>
    
    <app-about-me></app-about-me>
    <div class="section3">
        <div id="sub1">
            <div class="header">
                <h2>
                    Skills
                </h2>

            </div>
            <div class="bubble-cont">
                <div class="bubble">Angular2</div>
                <div class="bubble">React</div>
                <div class="bubble">Javascript</div>
                <div class="bubble">Typescript</div>
                <div class="bubble">Python</div>
                <div class="bubble">C++</div>
                <div class="bubble">Java</div>
                <div class="bubble">CSS</div>
                <div class="bubble">CICD</div>

            </div>
        </div>
        <div id="sub2">
            
            <div class="header">
                <h2>
                    Education
                </h2>

            </div>
            <div class="bubble-cont">
                <div class="bubble">B.S. Computer Science</div>
                <div class="bubble">Minor in Cyber Security</div>
                

            </div>
        </div>
        <div id="sub3">
            <div class="header">
                <h2>
                    Hobbies
                </h2>

            </div>
            <div class="bubble-cont">
                <div class="bubble">Flying</div>
                <div class="bubble">Music</div>
                <div class="bubble">Game Development</div>
                <div class="bubble">Pickleball</div>
                <div class="bubble">Carpentry</div>

            </div>
        </div>
    
    </div>
    <br>
    <!-- <app-portfolio></app-portfolio> -->
    <app-tabbed-info></app-tabbed-info>
</div>
