import { Component } from '@angular/core';

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrl: './portfolio.component.scss',
    standalone: false
})
export class PortfolioComponent {

}
