import { Component } from '@angular/core';

@Component({
    selector: 'app-tabbed-info',
    templateUrl: './tabbed-info.component.html',
    styleUrl: './tabbed-info.component.scss',
    standalone: false
})
export class TabbedInfoComponent {

  exp = [
    {
      name: "Citigroup",
      start: "April 2020",
      end: "Present",
      info: "Over my time here I've worked my way up to lead UI developer. I managed a team of up to 8 other developers building out large complex forms. I built and maintained CICD pipelines to ensure smooth deployments across .com and .net environments. I also participated in multiple internal hackathons over the years placing in the top 8 out of 200 entries with a Generative AI prompt.",
      tools: [
        "Angular",
        "Typescript",
        "CSS/SCSS",
        "Python",
        "Jenkins/RLM",
        "Tekton/Harness"
      ]
    },
    {
      name: "AAA Storage",
      start: "April 2024",
      end: "Present",
      info: "AAA Storage is a family owned vehicle storage lot located in Texas. I built out a simple informatoinal website for customers to look at, and an internal tool to manage the storage lot and renter information",
      tools: [
        "Next.js",
        "React",
        "Typescript",
        "CSS/SCSS",
        "Node.js"
      ]
    }
  ]

  curTab = 0



}
