<div class="container">
    <!-- <img src="assets/mountain_selfie.jpg" alt=""> -->
    <retro-tile>
        <h2>About Me</h2>
        <br>
        
        <p>
            With a background in Computer Science from Texas A&M and experience leading web development teams at major financial institutions, I bring a blend of technical expertise, creative problem-solving, and a passion for innovation to every project I tackle. My work has spanned modernizing small business loan applications with cutting-edge technologies, implementing micro-frontend architectures to securely handle sensitive data, and crafting intuitive, multi-step user interfaces that transform complex workflows into streamlined experiences. Along the way, I've discovered a love for open knowledge sharing and collaborative approaches, as well as a talent for envisioning long-term solutions that integrate seamlessly into evolving ecosystems. If you're looking for a developer who can adapt quickly, think holistically, and deliver high-quality digital tools that feel as intuitive as they are impactful, I'd be happy to explore how we can work together.
        </p>
        
    </retro-tile>

</div>

