import axios from 'axios';
import { getUserAgentHeader, createHttpClient } from 'contentful-sdk-core';
import { createGlobalOptions } from './create-global-options.js';
import { makeClient } from './make-client.js';
import { validateResolveLinksParam, validateRemoveUnresolvedParam } from './utils/validate-params.js';

/**
 * Contentful Delivery API SDK. Allows you to create instances of a client
 * with access to the Contentful Content Delivery API.
 */
/**
 * Create a client instance
 * @param params - Client initialization parameters
 * @category Client
 * @example
 * ```typescript
 * const contentful = require('contentful')
 * const client = contentful.createClient({
 *   accessToken: 'myAccessToken',
 *   space: 'mySpaceId'
 * })
 * ```
 */
function createClient(params) {
  if (!params.accessToken) {
    throw new TypeError('Expected parameter accessToken');
  }
  if (!params.space) {
    throw new TypeError('Expected parameter space');
  }
  validateResolveLinksParam(params);
  validateRemoveUnresolvedParam(params);
  const defaultConfig = {
    resolveLinks: true,
    removeUnresolved: false,
    defaultHostname: 'cdn.contentful.com',
    environment: 'master'
  };
  const config = Object.assign(Object.assign({}, defaultConfig), params);
  const userAgentHeader = getUserAgentHeader(`contentful.js/${"11.3.0"}`, config.application, config.integration);
  config.headers = Object.assign(Object.assign({}, config.headers), {
    'Content-Type': 'application/vnd.contentful.delivery.v1+json',
    'X-Contentful-User-Agent': userAgentHeader
  });
  const http = createHttpClient(axios, config);
  if (!http.defaults.baseURL) {
    throw new Error('Please define a baseURL');
  }
  const getGlobalOptions = createGlobalOptions({
    space: config.space,
    environment: config.environment,
    spaceBaseUrl: http.defaults.baseURL,
    environmentBaseUrl: `${http.defaults.baseURL}environments/${config.environment}`
  });
  // Append environment to baseURL
  http.defaults.baseURL = getGlobalOptions({}).environmentBaseUrl;
  return makeClient({
    http,
    getGlobalOptions
  });
}
export { createClient };