import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogComponent } from './blog/blog.component';
import { ContentfulService } from './contentful.service';
import { BlogNavbarComponent } from './blog/blog-navbar/blog-navbar.component';
import { BlogTileComponent } from './blog/blog-tile/blog-tile.component';
import { TypewritterComponent } from './blog/typewritter/typewritter.component';
import { RetroTileComponent } from './blog/retro-tile/retro-tile.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AboutMeComponent } from './landing-page/about-me/about-me.component';
import { PortfolioComponent } from './landing-page/portfolio/portfolio.component';
import { TabbedInfoComponent } from './landing-page/tabbed-info/tabbed-info.component';


@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    BlogNavbarComponent,
    BlogTileComponent,
    TypewritterComponent,
    RetroTileComponent,
    LandingPageComponent,
    AboutMeComponent,
    PortfolioComponent,
    TabbedInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [ContentfulService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
